import React from "react"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { Video, AboutJK } from "@components/Secondary"
import { splitText } from "@utils"

const WebinarPage = ({ data }) => {
  const { themeColor } = usePageSetup({ themeColor: "bg-white" })

  return (
    <Layout>
      <Seo title="Webinar" description="" />

      <section className={themeColor}>
        <Hero>
          <Hero.Title>
            <h1
              data-aos="stagger"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-gray-darkest": "A nimble approach",
                  break: "",
                  "text-teal": `for the “new normal”.`,
                }),
              }}
            />
          </Hero.Title>
          <Hero.Message overide={true}>
            <div className="grid w-full grid-cols-4 sm:grid-cols-10 gap-x-2 sm:gap-x-4 mt-11">
              <div className="col-span-3 col-start-2 sm:col-start-4 sm:col-span-6">
                <div data-aos="fade-up">
                  <p>
                    <span className="font-bold">This one-hour on-demand webinar</span> provides a practical framework
                    for nimbly adapting your marketing plans and messaging to the emerging realities of your
                    marketplace.
                  </p>
                </div>
              </div>
            </div>
          </Hero.Message>
        </Hero>
      </section>

      <main>
        <Video.Wrapper>
          <Video id="421164739" title="A nimble approach for the “new normal”." />
        </Video.Wrapper>
        <AboutJK />
      </main>
    </Layout>
  )
}

export default WebinarPage
