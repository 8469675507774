import React from "react"

export const Video = ({ id, title = "video", loop = 0  }) => {
  return (
    <div data-aos="fade-up" className="embed-container">
      <iframe
        src={`https://player.vimeo.com/video/${id}?loop=${loop}`}
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen={true}
        title={title}
      ></iframe>
    </div>
  )
}

const VideoWrapper = function({ backgroundBottomColor = "bg-teal-light", backgroundTopColor = "bg-white", children }) {
  return (
    <section className={`relative ${backgroundBottomColor}`}>
      <div className="absolute inset-0">
        <div className={`w-full ${backgroundTopColor} h-1/2`}></div>
      </div>
      <div className="container">
        {children}
      </div>
    </section>
  )
}

Video.Wrapper = VideoWrapper
